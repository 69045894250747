<template>
    <div class="container">
        <div class="row d-flex justify-content-center m-4">
            <h3>Content Shield Scan Results</h3>
        </div>
        <div class="row" v-infinite-scroll="loadmore" infinite-scroll-distance="10">
            <div class="col-4 col-md-4 mt-3" v-for="result in results" :key="result._id" data-aos="fade-up" data-aos-offset="100" data-aos-easing="ease-out-back">
                <b-card-group deck>
                    <b-card :title="result.filename" :img-src="`${$root.serverUrl}/admin/download?url=${encodeURI(result.storage.perm)}`" img-alt="Image" img-top>
                        <b-card-text>
                            <p class="mb-1">Source Link: <a :href="result.link" v-text="'Image Source'" /></p>
                            <p>Source URL: <a :href="result.url" v-text="result.url" /></p>
                        </b-card-text>
                        <template #footer>
                            <small class="text-muted">{{ result.created_ts }}</small>
                        </template>
                    </b-card>
                </b-card-group>
            </div>
        </div>
        <div class="row d-flex justify-content-center m-4" v-if="loading && max <=total">
            <img src="/loading.gif" />
        </div>
        <div v-if="max >= total" class="row d-flex justify-content-center m-4">
            <h3 class="text-muted">Total Results: {{ this.total }}</h3>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
let infiniteScroll = require('vue-infinite-scroll');
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export default {
    name: "scanResults",
    directives: {
        infiniteScroll
    },
    data() {
         return {
             max: 9,
             total: 0,
             loading: false,
             results: []
        }
    },
    created() {
      this.getResult();
    },
    methods: {
        loadmore() {
            if (this.max < this.total) {
                this.max = this.max + 3
                this.getResult()
            }
        },
        getResult() {
            this.loading = true
            axios.get(`${this.$root.serverUrl}/admin/results`, { params: {max: this.max} }).then(async (resp) => {
                this.total = resp.data.total
                this.results = (resp && resp.data) ? resp.data.data : []
                if ( this.max < this.total )
                    this.loading = false
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.$root.preloader = false;
            })
        }
    }
}
</script>

<style scoped>

</style>
